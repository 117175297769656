export enum ColorVariants{ 
BLACK = "black",
WHITE = "white",
WHITE2 = "white2",
SALMON = "salmon",
LIGHTBLUE = "lightBlue",
DARKBLUE = "darkBlue",
LIGHTGREEN = "lightGreen",
DARKGREEN = "darkGreen",
LIGHTGRAY = "lightGray",
VERYDARKGRAY = "veryDarkGray",
LIGHTVIOLET = "lightViolet",
DARKYELLOW = "darkYellow",
LIGHTYELLOW = "lightYellow",
LIGHTRED = "lightRed",
BODYPRIMARY = "bodyPrimary",
TEXTHEADINGPRIMARY = "textHeadingPrimary",
SECONDARY = "secondary",
PRIMARY = "primary",
}